/**
 * Featured News/Events and Community Spotlight
 */

import Button from "../common/Button";

function FeaturedNewsBlock() {
	return (
		<div className="grid md:grid-cols-2 gap-8 mb-8">
			{/* Featured Events */}
			<div className="bg-sky-50 shadow rounded-lg p-6">
				<h3 className="text-xl font-bold mb-4">Featured Events</h3>
				<ul className="space-y-2">
					<li>Balihang Tangnaam - TBD</li>
					<li>Mange Sakrati - TBD</li>
					<li>Sisekpa Tangnaam - TBD</li>
				</ul>
			</div>

			{/* Community Spotlight */}
			<div className="bg-fuchsia-50 shadow rounded-lg p-6">
				<h3 className="text-xl font-bold mb-4">Community Spotlight</h3>
				<p>
					This month we're celebrating the achievements of our
					community members!
				</p>
				<Button text="Read More" />
			</div>
		</div>
	);
}

export default FeaturedNewsBlock;
