import { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useSwipeable } from "react-swipeable";

import { slides } from "../../data/slides";

export default function ImageSlider() {
	const [currentIndex, setCurrentIndex] = useState(0);

	const prevSlide = () => {
		const isFirstSlide = currentIndex === 0;
		const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
	};

	const nextSlide = () => {
		const isLastSlide = currentIndex === slides.length - 1;
		const newIndex = isLastSlide ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
	};

	const handlers = useSwipeable({
		onSwipedLeft: () => nextSlide(),
		onSwipedRight: () => prevSlide(),
		swipeDuration: 500,
		preventScrollOnSwipe: true,
		trackMouse: true,
	});

	return (
		<div className="h-[450px] w-full relative group" {...handlers}>
			<div
				style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
				className="w-full h-[400px] rounded-2xl bg-center bg-cover duration-500"
			></div>
			<div className="absolute bottom-4 left-4 py-2 px-4 bg-slate-300 shadow-custom-shadow">
				<h2 className="text-2xl font-bold">
					{slides[currentIndex].title}
				</h2>
				<p className="text-sm">{slides[currentIndex].subtitle}</p>
			</div>
			<button
				className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 cursor-pointer"
				onClick={prevSlide}
			>
				<HiChevronLeft className="h-6 w-6" color="white" />
				<span className="sr-only">Previous slide</span>
			</button>
			<button
				className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 cursor-pointer"
				onClick={nextSlide}
			>
				<HiChevronRight className="h-6 w-6" color="white" />
				<span className="sr-only">Next slide</span>
			</button>
		</div>
	);
}
