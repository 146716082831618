import React from "react";

import { Link } from "react-router-dom";

import logo from "../../images/logo-55x55.png";
import Menu from "../features/Menu";
import useWindowSize from "../../hooks/useWindowSize";

function Header() {
	const { width } = useWindowSize();

	const isMobile = width <= 768;

	return (
		<header className="bg-lightGray body-font">
			{isMobile ? (
				<div className="container mx-auto max-w-6xl px-5 py-5 flex flex-row gap-3">
					<Menu className="w-auto" />

					<Link
						to="/"
						className="flex title-font font-medium items-center md:mb-0"
					>
						<img src={logo} alt="logo" />
						<span className="ml-3 text-xl">Yakthung W.C. UK</span>
					</Link>
				</div>
			) : (
				<div className="container mx-auto max-w-6xl px-5 py-5 flex flex-wrap flex-col md:flex-row items-center">
					<Link
						to="/"
						className="flex title-font font-medium items-center mb-4 md:mb-0"
					>
						<img src={logo} alt="logo" />
						<span className="ml-3 text-xl">
							Yakthung Wensa Chumbho UK
						</span>
					</Link>

					<Menu />
				</div>
			)}
		</header>
	);
}

export default Header;
