import { FaMapMarkedAlt } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import PageLayout from "../layout/PageLayout";
import PageTitle from "../common/PageTitle";

function ContactUsPage() {
	const address = "ME18 6ET";
	const addressPath = `https://www.google.com/maps?q=${encodeURIComponent(
		address
	)}&z=15`;

	const content = (
		<>
			<PageTitle title="Contact Us" />
			<div className="contact-item flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md mb-6">
				<div>
					<a
						href={addressPath}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaMapMarkedAlt size={64} className="mb-5 pl-2" />
					</a>
					<h2 className="text-xl font-semibold text-gray-800 mb-2">
						Yakthung Wensa Chumbho UK
					</h2>
					<p className="text-gray-600 pb-2">
						Chumlungden, Yalding, Maidstone, UK
					</p>
					<p className="text-gray-600 pb-2">
						<a
							href={addressPath}
							target="_blank"
							rel="noopener noreferrer"
						>
							{address}
						</a>
					</p>
					<p className="text-gray-600">
						<a
							href="mailto:info@yakthungwensachumbhouk.com"
							className="flex gap-2"
						>
							<CiMail />
							<span>info@yakthungwensachumbhouk.com</span>
						</a>
					</p>
					<p>
						<a
							href={addressPath}
							target="_blank"
							rel="noopener noreferrer"
							className="block w-fit mt-4 px-6 py-3 bg-red-400 text-white rounded-lg hover:bg-red-600 transition-colors"
						>
							View in map
						</a>
					</p>
				</div>
			</div>
			<div className="contact-item flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md">
				<div>
					<a
						href={addressPath}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaMapMarkedAlt size={64} className="mb-5 pl-2" />
					</a>
					<h2 className="text-xl font-semibold text-gray-800 mb-2">
						Kirat Yakthung Chumlung UK
					</h2>
					<p className="text-gray-600 pb-2">
						Chumlungden, Yalding, Maidstone, UK
					</p>
					<p className="text-gray-600 pb-2">
						<a
							href={addressPath}
							target="_blank"
							rel="noopener noreferrer"
						>
							{address}
						</a>
					</p>
					<p className="text-gray-600">
						<a
							href="mailto:chumlunguk.org@gmail.com"
							className="flex gap-2"
						>
							<CiMail />
							<span>chumlunguk.org@gmail.com</span>
						</a>
					</p>
					<p>
						<a
							href={addressPath}
							target="_blank"
							rel="noopener noreferrer"
							className="block w-fit mt-4 px-6 py-3 bg-red-400 text-white rounded-lg hover:bg-red-600 transition-colors"
						>
							View in map
						</a>
					</p>
				</div>
			</div>
		</>
	);

	return <PageLayout children={content} />;
}

export default ContactUsPage;
