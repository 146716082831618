import React from "react";
import PageLayout from "../layout/PageLayout";
import PageTitle from "../common/PageTitle";

function MissionPage() {
	const content = (
		<div>
			<PageTitle title="Our Mission" />

			<p className="text-lg mb-4">
				To actively co-operate in Kirat Yakthung Chumlung UK’s social
				and cultural initiatives.
			</p>

			<p className="text-lg mb-4">
				To develop youths' understanding of Limbu cultural heritage and
				encourage their active participation in its preservation and
				promotion efforts.
			</p>

			<p className="text-lg mb-4">
				To contribute to the educational, social and cultural
				development of Limbu youths.
			</p>

			<p className="text-lg mb-4">
				To facilitate the development and transfer of Kirat Yakthung
				Chumlung UK’s future leadership to the next generation.
			</p>
		</div>
	);
	return <PageLayout children={content} />;
}

export default MissionPage;
