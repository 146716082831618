import React, { useState } from "react";

import PageTitle from "../../common/PageTitle";
import PageLayout from "../../layout/PageLayout";
import { Link } from "react-router-dom";
import { eventsData } from "../../../data/events";
import Button from "../../common/Button";

function FestivalsPage() {
	const festivals = eventsData.filter((event) => event.type === "festival");

	const content = (
		<div>
			<PageTitle title="Festivals" />

			{festivals.length ? (
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
					{festivals?.map((festival) => (
						<Link
							key={"festival-" + festival.id}
							to={`/festival/${festival.id}`}
							className="block"
						>
							<div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out h-[340px]">
								{festival.imageUrl ? (
									<img
										src={festival.imageUrl}
										alt={festival.name}
										className="w-full h-48 object-cover"
									/>
								) : (
									<div className="bg-slate-200 w-full h-48 object-cover"></div>
								)}

								<div className="p-4">
									<h2 className="text-xl font-semibold text-gray-800 mb-1">
										{festival.title}
									</h2>
									<p className="h-[32px] overflow-hidden">
										{festival.summary}
									</p>

									<Button text="Read more" />
								</div>
							</div>
						</Link>
					))}
				</div>
			) : (
				<p>No festivals found.</p>
			)}
		</div>
	);

	return <PageLayout children={content} />;
}

export default FestivalsPage;
