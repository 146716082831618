import { useState, useEffect } from "react";

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Call the handler initially to set the right size
		handleResize();

		// Clean up event listener on unmount
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures the effect is only run on mount and unmount

	return windowSize;
}

export default useWindowSize;
