import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillTikTok } from "react-icons/ai";

import logo from "../../images/logo-small.png";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<footer className="bg-gray-900 text-lightGray body-font">
			<div className="container mx-auto max-w-6xl px-5 py-8 flex items-center sm:flex-row flex-col">
				<span className="ml-3 mb-3 md:mb-0 text-xl">
					<Link to="https://chumlunguk.org/" target="_blank">
						<img src={logo} alt="Kiract Yakthung Chumlung UK" />
					</Link>
				</span>
				<span className="ml-3 text-xl">
					<img src={logo} alt="Yakthung Wensa Chumbho UK" />
				</span>
				<p className="text-sm sm:ml-2 sm:border-l-2 sm:border-gray-800 sm:py-2 sm:mt-0 mt-4">
					© {new Date().getFullYear()} Yakthung Wensa Chumbho UK
				</p>

				<span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
					<div className="social-links inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start gap-2">
						<div className="social-facebook pt-[2px]">
							<a
								href="https://www.facebook.com/profile.php?id=100082807702641"
								target="_blank"
								rel="noreferrer noopener"
							>
								<FaFacebookSquare size={20} color="#9ca3b0" />
							</a>
						</div>
						<div className="social-youtube">
							<a
								href="https://www.youtube.com/@YakthungWensaChumbhoUK"
								target="_blank"
								rel="noreferrer noopener"
							>
								<IoLogoYoutube size={22} color="#9ca3b0" />
							</a>
						</div>
						<div className="social-tiktok">
							<a
								href="https://www.tiktok.com/@yakthungwensachumbhouk"
								target="_blank"
								rel="noreferrer noopener"
							>
								<AiFillTikTok size={22} color="#9ca3b0" />
							</a>
						</div>
					</div>
				</span>
			</div>
		</footer>
	);
}

export default Footer;
