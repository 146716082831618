import React from "react";
import PageLayout from "../layout/PageLayout";
import PageTitle from "../common/PageTitle";

function VisionPage() {
	const content = (
		<div>
			<PageTitle title="Our Vision" />

			<p className="text-lg mb-4 text-center">
				<blockquote class="border-l-4 border-blue-500 pl-4 italic text-gray-700">
					A people without knowledge of their history, origin and
					culture is like a tree without roots - Marcus Garvey
				</blockquote>
			</p>

			<p className="text-lg">
				Our history and cultural background make us who we are—our
				beliefs, values, and behaviours. Our heritage, which we have
				inherited from past generations, is now this generation's
				responsibility to value and preserve it and pass it on to future
				generations. Acknowledging this, our vision is to organise and
				encourage the involvement of Yakthung/Limbu youths in
				collecting, preserving, and interpreting our community's unique
				history, culture, traditions, Mundhum, rituals, language,
				scriptures, artefacts, music, etc. Our initiatives are guided by
				3Ps: Preserve, Promote and Practice.
			</p>
		</div>
	);
	return <PageLayout children={content} />;
}

export default VisionPage;
