import React from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";
import FeedbackButton from "../features/FeedbackButton";

function PageLayout({ children }) {
	return (
		<>
			<div className="flex flex-col min-h-screen">
				<Header />

				<main className="flex-grow">
					<div className="container mx-auto max-w-6xl px-4 py-8">
						{children}
					</div>
				</main>

				<Footer />
			</div>

			<FeedbackButton />
		</>
	);
}

export default PageLayout;
