import React from "react";

function PageTitle({ icon, title, subtitle }) {
	return (
		<div className="text-center my-10">
			<h1 className="text-4xl font-bold text-gray-800 md:text-5xl lg:text-6xl flex justify-center gap-[8px]">
				{icon && <p className="pt-[6px] text-primary">{icon}</p>}
				{title}
			</h1>
			{subtitle && (
				<p className="text-lg text-gray-500 mt-4 md:text-xl lg:text-2xl">
					{subtitle}
				</p>
			)}
		</div>
	);
}

export default PageTitle;
