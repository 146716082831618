import React from "react";
import { Link } from "react-router-dom";
import { RiFeedbackFill } from "react-icons/ri";

function FeedbackButton() {
	return (
		<Link
			to="/feedback"
			className="hidden fixed right-[-25px] top-1/2 transform -translate-y-1/2 bg-red-500 text-white px-4 py-2  shadow-lg flex items-center justify-center hover:bg-red-600 transition-colors transform rotate-90 z-10 gap-[6px]"
		>
			<RiFeedbackFill />
			Feedback
		</Link>
	);
}

export default FeedbackButton;
