import { IoReader } from "react-icons/io5";

export default function Button({ text = "Read More", onClick, className }) {
	const defaultClasses =
		"mt-4 px-4 py-2 bg-red-400 text-white rounded hover:bg-red-600 flex gap-[6px]";
	const mergedClasses = `${defaultClasses} ${className}`;

	return (
		<button className={mergedClasses} onClick={onClick}>
			<IoReader size={20} />
			<p className="pt-[3px]">{text}</p>
		</button>
	);
}
