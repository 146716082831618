/**
 * Slide data for the home page slider
 *
 */
export const slides = [
	{
		url: "https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/wensa-group-1.png?alt=media&token=f710ac50-090e-4fda-b687-bcdbae7dc861",
		title: "Sisekpa Tangnaam",
		subtitle: "Sisekpa Tangnaam is our annual event was held in 2024",
	},
	{
		url: "https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/ashford-event-wensa.png?alt=media&token=8a1ff284-a92b-4cd7-ba98-6baae2a52f0d",
		title: "Yakthung Wensa Chumbho UK Conference",
		subtitle: "Conference was held in Ashford, Kent in 2024",
	},
];
