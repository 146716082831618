import React, { useState } from "react";
import PageTitle from "../../common/PageTitle";
import PageLayout from "../../layout/PageLayout";
import { useNavigate } from "react-router-dom";
import { eventsData } from "../../../data/events";
import Button from "../../common/Button";
import Accordion from "../../common/Accordion";
import { MdOutlineEventAvailable } from "react-icons/md";

function EventsPage() {
	const navigate = useNavigate();

	const [events, setEvents] = useState(eventsData);

	// State for search query and filtered events
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredEvents, setFilteredEvents] = useState(events);

	const handleSelectEvent = (event) => {
		if (!!!event) return;

		switch (event.type) {
			case "festival":
				// console.log("event", event.id);
				navigate("/festival/" + event.id);
				break;
			case "event":
			default:
				navigate("/coming-soon");
				break;
		}
	};

	const list = filteredEvents.map((event) => {
		let content = (
			<>
				<p>{event.summary}</p>
				<Button text="View" onClick={() => handleSelectEvent(event)} />
			</>
		);
		return (
			<Accordion key={event.id} title={event.title} content={content} />
		);
	});

	// Handle search input change
	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		// Filter events based on title
		const filtered = events.filter((event) =>
			event.title.toLowerCase().includes(query)
		);
		setFilteredEvents(filtered);
	};

	const icon = <MdOutlineEventAvailable size={50} />;

	const content = (
		<div>
			<PageTitle title="Events" />

			{/* Search Input */}
			<div className="flex justify-start items-start mt-4">
				<input
					type="text"
					value={searchQuery}
					onChange={handleSearch}
					placeholder="Search events"
					className="p-2 border border-gray-300 rounded-md"
				/>
			</div>
			<div className="my-4">{list}</div>
		</div>
	);

	return <PageLayout children={content} />;
}

export default EventsPage;
