function WelcomeBlock() {
	return (
		<div className="bg-purple-100 shadow rounded-lg p-6 mb-8">
			<h2 className="text-2xl font-bold mb-2 text-4xl">
				Welcome,
				<br />
				<span className="text-2xl">
					Yakthung Wensa Chumbho UK is a sister organisation of Kirat
					Yakthung Chumlung UK.
				</span>
			</h2>
			<p className="pb-2">
				It was founded on 29 June 2024 with the vision to organise and
				involve Yakthung/Limbu youths residing in the UK to preserve and
				promote Yakthung/Limbu cultural heritage.
			</p>
			<p className="text-gray-600 mb-4 text-sm">
				As a not-for-profit organisation, Yakthung Wensa Chumbho UK is
				funded by the generous contributions of its community.
			</p>
			<p className="leading-6">
				*‘Limbu’ and ‘Yakthung’ are used interchangeably.{" "}
			</p>
			<p className="leading-6">
				*Yakthung/Limbu is the ancient inhabitants of eastern Nepal.
			</p>
		</div>
	);
}

export default WelcomeBlock;
