import React from "react";
import PageLayout from "../../layout/PageLayout";
import PageTitle from "../../common/PageTitle";

import { news } from "../../../data/news";

function NewsPage() {
	// sort news by id desc
	news.sort((a, b) => b.id - a.id);

	const content = (
		<div>
			<PageTitle title="News" />

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
				{news.map((item) => (
					<NewsItem key={item.id} {...item} />
				))}
			</div>
		</div>
	);

	return <PageLayout children={content} />;
}

const NewsItem = ({ title, description, date, imageUrl }) => {
	return (
		<div className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer">
			{imageUrl ? (
				<img
					src={imageUrl}
					alt={title}
					className="w-full h-48 object-cover"
				/>
			) : (
				<div className="w-full h-48 bg-gray-300"></div>
			)}

			<div className="p-4">
				<h2 className="text-xl font-semibold mb-2">{title}</h2>
				<p className="text-gray-600">{description}</p>
				<p className="text-gray-400 py-2 text-sm">{date}</p>
			</div>
		</div>
	);
};

export default NewsPage;
