export const news = [
	{
		id: 2,
		title: "Balihang Tangnam 2024",
		description:
			"Held in Ashford and Folkestone was a great success brining the community together.",
		date: "2024-11-1",
		// imageUrl: "https://placehold.co/300x200",
	},
	{
		id: 1,
		title: "KYC Conference",
		description: "TBD",
		date: "2024-09-13",
		// imageUrl: "https://placehold.co/300x200",
	},
];
