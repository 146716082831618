import React from "react";

import PageLayout from "../layout/PageLayout";
import ImageSlider from "../common/ImageSlider";
import WelcomeBlock from "../features/WelcomeBlock";
import FeaturedNewsBlock from "../features/FeaturedNewsBlock";

function HomePage() {
	const content = (
		<>
			<WelcomeBlock />

			<ImageSlider />

			<FeaturedNewsBlock />
		</>
	);

	return <PageLayout children={content} />;
}

export default HomePage;
