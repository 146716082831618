import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Modal from "./MenuModal";
import useWindowSize from "../../hooks/useWindowSize";

function Menu({ className }) {
	const [isOpen, setIsOpen] = useState(false);

	const defaultClasses =
		"md:ml-auto flex flex-wrap items-center text-base justify-center md:w-auto";
	let mergedClasses = `${defaultClasses} ${className}`;

	const { width } = useWindowSize();
	const isMobile = width <= 768;

	const widthStyle = isMobile ? "w-auto" : "w-full";

	if (!isMobile) {
		mergedClasses += "w-full";
	}

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<nav className={mergedClasses}>
			{/* Desktop menu (visible on medium+ screens) */}
			<div className="hidden md:flex navbar">
				<Link to="/" className="mr-5 hover:text-red-400">
					Home
				</Link>
				<div className="dropdown mr-5">
					<button className="dropbtn">
						<Link to="/our-vision" className="hover:text-red-400">
							About Us
						</Link>
					</button>
					<div className="dropdown-content">
						<div className="">
							<Link
								to="/our-vision"
								className="hover:text-red-400"
							>
								Our Vision
							</Link>
						</div>
						<div className="">
							<Link to="/our-mission">Our Mission</Link>
						</div>
						<Link to="/team">Our Team</Link>
						<div className="hidden">
							<Link to="/our-policy">Our Policy</Link>
						</div>
					</div>
				</div>
				<div className="dropdown mr-5 hidden">
					<button className="dropbtn">
						<Link to="/calendar" className="hover:text-red-400">
							Limbu Heritage
						</Link>
					</button>
					<div className="dropdown-content">
						<Link to="/calendar" className="hover:text-red-400">
							Calendar
						</Link>
						<Link to="/festivals">Festivals</Link>
						<Link to="/events">Events</Link>
					</div>
				</div>

				<Link to="/news" className="mr-5 hover:text-red-400">
					News
				</Link>

				<Link to="/calendar" className="mr-5 hover:text-red-400">
					Calendar
				</Link>

				<Link to="/contact-us" className="mr-5 hover:text-red-400">
					Contact Us
				</Link>
			</div>

			{/* Burger icon (visible on small screens) */}
			<div className={`md:hidden flex items-center ${widthStyle}`}>
				<div className={`flex justify-start ${widthStyle} px-3`}>
					<IoMenu size={40} onClick={toggleMenu} />
				</div>
			</div>

			{/* Mobile Menu (visible only on small screens) */}
			{isOpen && <Modal isOpen={isOpen} closeModal={toggleMenu} />}
		</nav>
	);
}

export default Menu;
