import React from "react";
import PageTitle from "../../common/PageTitle";
import PageLayout from "../../layout/PageLayout";

function GalleryPage() {
	const content = (
		<div>
			<PageTitle title="Gallery" />
		</div>
	);

	return <PageLayout children={content} />;
}

export default GalleryPage;
