import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import PageTitle from "../../common/PageTitle";
import PageLayout from "../../layout/PageLayout";
import { eventsData } from "../../../data/events";
import Description from "../../common/Description";

function FestivalPage() {
	const navigate = useNavigate();

	// get id from url
	const { id } = useParams();

	const festival = eventsData.find(
		(festival) => festival.id === id && festival.type === "festival"
	);

	useEffect(() => {
		if (!festival) {
			navigate("/notfound");
		}
	}, [festival]);

	const content = (
		<div>
			{festival && (
				<>
					<PageTitle title={festival.title} />

					<p className="text-slate-400 leading-6 pb-4">
						{festival.summary}
					</p>

					{festival.description && (
						<Description description={festival.description} />
					)}

					{festival.postcode && (
						<div className="btn">
							<a
								href={`https://www.google.com/maps?q=${festival.postcode}&z=15`}
								target="_blank"
								className="block w-fit mt-4 px-6 py-3 bg-red-400 text-white rounded-lg hover:bg-red-600 transition-colors"
							>
								View on Map
							</a>
						</div>
					)}
				</>
			)}
		</div>
	);

	return <PageLayout children={content} />;
}

export default FestivalPage;
