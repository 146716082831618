import React, { useState } from "react";

function Accordion({ title, content }) {
	const [isOpen, setIsOpen] = useState(false); // State to manage accordion open/close

	const toggleAccordion = () => {
		setIsOpen(!isOpen); // Toggle the open/close state
	};

	return (
		<div className="mb-2">
			{/* Accordion Header */}
			<button
				onClick={toggleAccordion}
				className="w-full text-left py-4 px-2 flex justify-between items-center focus:outline-none bg-slate-300"
			>
				<h3 className="text-lg font-medium">{title}</h3>
				{/* Chevron Icon */}
				<span
					className={`transform transition-transform duration-300 ${
						isOpen ? "rotate-180" : "rotate-0"
					}`}
				>
					<svg
						className="w-6 h-6 text-gray-500"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M19 9l-7 7-7-7"
						></path>
					</svg>
				</span>
			</button>

			{/* Accordion Content */}
			<div
				className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
					isOpen ? "max-h-96" : "max-h-0"
				}`}
			>
				<div className="px-2 py-4 text-gray-700">{content}</div>
			</div>
		</div>
	);
}

export default Accordion;
